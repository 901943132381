import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type OptionalSegment = {
  link: string;
  title: string;
};

type OptionalSegmentResult = OptionalSegment | null;

const breadcrumbsOptionalSegmentMap = new Map([
  // PROFILES
  [
    '/reservations/profiles/individual/:id/merge/:id',
    {
      link: 'profiles/duplicates',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DUPLICATES',
    },
  ],
  [
    '/reservations/profiles/individual/:id/details',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/individual/:id/activity-reservations',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/individual/:id/accompanying',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/individual/:id/groups',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/individual/:id/meetings-and-events',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/individual/:id/analysis',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/individual/:id/sales-activities',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/individual/:id/reservations',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/individual/:id/tasks',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],

  [
    '/reservations/profiles/company/:id/details',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/company/:id/reservations',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/company/:id/activity-reservations',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/company/:id/groups',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/company/:id/meetings-and-events',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/company/:id/analysis',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/company/:id/sales-activities',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/company/:id/tasks',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],

  [
    '/reservations/profiles/travel-agent/:id/details',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/travel-agent/:id/reservations',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/travel-agent/:id/activity-reservations',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/travel-agent/:id/groups',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/travel-agent/:id/meetings-and-events',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/travel-agent/:id/analysis',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/travel-agent/:id/sales-activities',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],
  [
    '/reservations/profiles/travel-agent/:id/tasks',
    {
      link: 'profiles/dashboard',
      title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD',
    },
  ],

  // RESERVATIONS
  [
    '/reservations/reservations/:id',
    {
      link: 'reservations/reservations',
      title: 'MENU.RESERVATIONS.BREADCRUMB.4SEGMENT.RESERVATIONS_DASHBOARD',
    },
  ],
  [
    '/inventory/items/inventory-item',
    {
      link: 'inventory/items',
      title: 'MENU.RESERVATIONS.BREADCRUMB.4SEGMENT.INVENTORY_ITEM_DASHBOARD',
    },
  ],
  [
    '/inventory/items/inventory-item/:id/assignment-timeline',
    {
      link: 'inventory/items',
      title: 'MENU.RESERVATIONS.BREADCRUMB.4SEGMENT.INVENTORY_ITEM_DASHBOARD',
    },
  ],
  // ACTIVITY RESERVATIONS
  [
    '/activity-reservations/reservation/:id',
    {
      link: 'activity-reservations/dashboard',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_MANAGER',
    },
  ],
  [
    '/activity-reservations/reservation/new',
    {
      link: 'activity-reservations/dashboard',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_MANAGER',
    },
  ],
  [
    '/activity-reservations/reservations/:id',
    {
      link: 'activity-reservations/dashboard',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_MANAGER',
    },
  ],
  [
    '/activity-reservations/reservations/new',
    {
      link: 'activity-reservations/dashboard',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_MANAGER',
    },
  ],
  [
    '/activity-configuration/rate-manager/add-rate',
    {
      link: 'activity-configuration/dashboard',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_RATE_MANAGER',
    },
  ],
  [
    '/activity-configuration/activity-manager/edit-activity/:id',
    {
      link: 'activity-configuration/activity-manager',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_MANAGER_DASHBOARD',
    },
  ],
  [
    '/activity-configuration/activity-manager/add-activity',
    {
      link: 'activity-configuration/activity-manager',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_MANAGER_DASHBOARD',
    },
  ],
  [
    '/activity-configuration/rate-manager/edit-rate/Default/:id',
    {
      link: 'activity-configuration/dashboard',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_RATE_MANAGER',
    },
  ],
  [
    '/activity-configuration/rate-manager/edit-rate/Standard/:id',
    {
      link: 'activity-configuration/dashboard',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_RATE_MANAGER',
    },
  ],
  [
    '/activity-configuration/package-manager/new',
    {
      link: 'activity-configuration/package-manager',
      title:
        'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_PACKAGE_MANAGER',
    },
  ],

  // RESOURCE MANAGEMENT
  [
    '/resource-management/resources/new',
    {
      link: 'resource-management/resources',
      title: 'MENU.RESOURCE_MANAGEMENT.BREADCRUMB.4SEGMENT.RESOURCE_DASHBOARD',
    },
  ],
  [
    '/resource-management/resources/:id',
    {
      link: 'resource-management/resources',
      title: 'MENU.RESOURCE_MANAGEMENT.BREADCRUMB.4SEGMENT.RESOURCE_DASHBOARD',
    },
  ],
  // GOLF
  [
    '/golf/reservations/new',
    {
      link: 'golf/reservations/dashboard',
      title: 'MENU.GOLF.BREADCRUMB.4SEGMENT.GOLF_DASHBOARD',
    },
  ],
  [
    '/golf/reservations/:id',
    {
      link: 'golf/reservations/dashboard',
      title: 'MENU.GOLF.BREADCRUMB.4SEGMENT.GOLF_DASHBOARD',
    },
  ],
  // RATE
  [
    '/rate-manager/exchange-rates/add-currency',
    {
      link: 'rate-manager/exchange-rates',
      title: 'MENU.RATES.BREADCRUMB.4SEGMENT.EXCHANGE_RATES',
    },
  ],
  // GROUPS
  [
    '/itinerary/integrated-booking/group/:id/group-details',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/booking-details',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/meetings-and-events-details',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/events-overview',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/event-details/:id',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/me-sales-activities',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/room-grid',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/rooming-list',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/group-sales-activities',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/package',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/itinerary/integrated-booking/group/:id/create-event',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  // CASHIERING
  [
    '/reservations/cashiering/accounts/:id/folios/:id',
    {
      link: 'cashiering/dashboard',
      title: 'MENU.CASHIERING.BREADCRUMB.4SEGMENT.CASHIERING_DASHBOARD',
    },
  ],
  [
    '/reservations/cashiering/billing/:id',
    {
      link: 'cashiering/dashboard',
      title: 'MENU.CASHIERING.BREADCRUMB.4SEGMENT.CASHIERING_DASHBOARD',
    },
  ],
  [
    '/reservations/cashiering/accounts/:id',
    {
      link: 'cashiering/dashboard',
      title: 'MENU.CASHIERING.BREADCRUMB.4SEGMENT.CASHIERING_DASHBOARD',
    },
  ],
  [
    '/reservations/cashiering/accounts/:id',
    {
      link: 'cashiering/dashboard',
      title: 'MENU.CASHIERING.BREADCRUMB.4SEGMENT.CASHIERING_DASHBOARD',
    },
  ],
  [
    '/cashiering/cashier-closure',
    {
      link: 'cashiering/number-list-view',
      title: 'MENU.CASHIERING.BREADCRUMB.4SEGMENT.CASHIER_STATUS',
    },
  ],
  [
    '/cashiering/cashier-closure/dashboard',
    {
      link: 'cashiering/number-list-view',
      title: 'MENU.CASHIERING.BREADCRUMB.4SEGMENT.CASHIER_STATUS',
    },
  ],
  [
    '/cashiering/cashier-closure/posting-journal',
    {
      link: 'cashiering/number-list-view',
      title: 'MENU.CASHIERING.BREADCRUMB.4SEGMENT.CASHIER_STATUS',
    },
  ],
  [
    '/reservations/reservations-groups/:id/room-grid',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.FRONTDESK.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/reservations/reservations-groups/:id/rooming-list',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.FRONTDESK.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/reservations/reservations-groups/:id/sales-activities',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.FRONTDESK.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  [
    '/reservations/reservations-groups/:id/details',
    {
      link: 'reservations-groups/dashboard',
      title: 'MENU.FRONTDESK.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD',
    },
  ],
  // FRONTDESK
  [
    '/reservations/reservations/:id/check-in',
    {
      link: 'reservations/frontdesk',
      title: 'MENU.FRONTDESK.BREADCRUMB.4SEGMENT.FRONT_DESK_DASHBOARD',
    },
  ],
  [
    '/individual-reservations/reservations/:id/check-in',
    {
      link: 'reservations/frontdesk',
      title: 'MENU.FRONTDESK.BREADCRUMB.4SEGMENT.FRONT_DESK_DASHBOARD',
    },
  ],
  // M&E
  [
    '/itinerary/integrated-booking/booking/:id/event-details/:id',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/booking-details',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/meetings-and-events-details',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/package',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/events-overview',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/me-sales-activities',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/group-details',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/room-grid',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/rooming-list',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/group-sales-activities',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/itinerary/integrated-booking/booking/:id/create-event',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/meetings-and-events/package/create',
    {
      link: 'meetings-and-events/packages',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.PACKAGES',
    },
  ],
  [
    '/meetings-and-events/package/:id',
    {
      link: 'meetings-and-events/packages',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.PACKAGES',
    },
  ],
  [
    '/meetings-and-events/booking/:id/events-overview',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/meetings-and-events/booking/:id/packages',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/meetings-and-events/booking/:id/event-details/:id',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/meetings-and-events/booking/:id/sales-activities',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/meetings-and-events/booking/:id/booking-details',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/meetings-and-events/booking/:id/create-event',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/changelog/changelog?Booking',
    {
      link: 'meetings-and-events/booking-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS',
    },
  ],
  [
    '/changelog/changelog?Event',
    {
      link: 'meetings-and-events/events-dashboard',
      title: 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.EVENTS_DASHBOARD',
    },
  ],
  // ACCOUNTS RECEIVABLE
  [
    '/accounts-receivable/:id/transactions',
    {
      link: '/accounts-receivable/dashboard',
      title:
        'MENU.ACCOUNTS_RECEIVABLE.BREADCRUMB.4SEGMENT.AR_ACCOUNT_DASHBOARD',
    },
  ],
  // INTEGRATIONS
  [
    '/integrations/webhook-management/new',
    {
      link: 'integrations/webhook-management',
      title: 'MENU.INTEGRATIONS.BREADCRUMB.4SEGMENT.WEBHOOK_MANAGEMENT',
    },
  ],
  [
    '/integrations/webhook-management/:id',
    {
      link: 'integrations/webhook-management',
      title: 'MENU.INTEGRATIONS.BREADCRUMB.4SEGMENT.WEBHOOK_MANAGEMENT',
    },
  ],
] as Iterable<[string, OptionalSegment]> | undefined);

const urlSearchSegmentMap = new Map([
  ['meetingsAndEventsEvent', 'Event'],
  ['meetingsAndEventsBooking', 'Booking'],
]);

const hashLength = 36;

const generateSelector = (pathName: string, search: string): string => {
  const pathSegments = pathName.slice(1).split('/');

  let url = pathSegments.reduce((acc, segment) => {
    if (segment.length === hashLength) {
      return `${acc}/:id`;
    } else {
      return segment.length ? `${acc}/${segment}` : acc;
    }
  }, '');

  if (search) {
    urlSearchSegmentMap.forEach((value, key) => {
      if (search.includes(key)) {
        url += `?${value}`;
      }
    });
  }

  return url;
};

export const useBreadCrumbsOptionalSegmentDictionary = (
  pathName: string,
  search: string
): OptionalSegmentResult => {
  const { t } = useTranslation();

  const hasOptionalSegment = useCallback((selector: string): boolean => {
    return breadcrumbsOptionalSegmentMap.has(selector);
  }, []);

  const getBreadcrumbSegment = useCallback(
    (selector: string): OptionalSegment => {
      return breadcrumbsOptionalSegmentMap.get(selector) as OptionalSegment;
    },
    []
  );

  return useMemo(() => {
    let optionalSegment = null;
    const selector = generateSelector(pathName, search);

    if (hasOptionalSegment(selector)) {
      const currentSegmentData = getBreadcrumbSegment(selector);

      optionalSegment = {
        title: t(currentSegmentData.title),
        link: currentSegmentData.link,
      };
    }

    return optionalSegment;
  }, [t, getBreadcrumbSegment, hasOptionalSegment, pathName, search]);
};
