import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { breadcrumbsUnitItemId } from 'frontend-container/components/Menu/components/Breadcrumbs/Breadcrumbs';
import { DateTimeHook } from 'frontend-container/components/Menu/components/Context';
import { GenericList } from 'frontend-container/components/Menu/components/Context/components/GenericList';
import { ContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { useContextsContext } from 'frontend-container/components/Menu/store/context';

import {
  AcSelectOption,
  AcSelectValue,
  AlignItems,
  Color,
  FlexDirection,
  TextColor,
} from '@ac/web-components';

import { ContextDropdown } from './components/ContextDropdown';
import { ContextHeader } from './components/ContextHeader';
import { Context, ContextType } from './context';

interface Props {
  selectedContext: Context | undefined;
  onSelect: (
    selected: Context | Array<AcSelectOption<AcSelectValue>>,
    discardUnsavedChanges?: boolean,
    tabId?: string
  ) => void;
  useDateTime: DateTimeHook;
  dateLabel: string;
  timeLabel: string;
  color?: string | Color | TextColor;
  thresholdToShowTabs?: number;
  isContextSwitchHidden?: boolean;
  triggerId?: string;
  shouldActiveConfiguration?: boolean;
}

export const ContextComponent = ({
  selectedContext,
  onSelect,
  useDateTime,
  dateLabel,
  timeLabel,
  color,
  thresholdToShowTabs = 1,
  isContextSwitchHidden = false,
  triggerId,
  shouldActiveConfiguration,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isListVisible, setIsListVisible] = useState<boolean>(
    isContextSwitchHidden
  );

  const { allContextLists, tabsOptions, currentButtonOption } =
    useContextsContext();

  const [activeTab, setActiveTab] = useState(
    currentButtonOption?.id || ContextOption.property
  );

  const { date, dateFormat, time } = useDateTime();

  const handleSetActiveTab = (newActiveTab: string): void => {
    setActiveTab(newActiveTab);
  };

  const openList = (): void => setIsListVisible(true);

  const closeList = (): void => setIsListVisible(false);

  const handleSelect = (context: AcSelectOption[] | Context): void => {
    onSelect(context, false, activeTab || '');
    closeList();
  };

  const isMenuV2EnabledCached = getIsMenuV2Enabled();

  const showTabs =
    (tabsOptions && tabsOptions?.length > thresholdToShowTabs) || false;

  useEffect(() => {
    if (
      selectedContext?.type === ContextType.PROPERTY &&
      activeTab !== ContextOption.property
    ) {
      handleSetActiveTab(ContextOption.property);
    }
    if (
      selectedContext?.type === ContextType.CRO &&
      activeTab !== ContextOption.cro
    ) {
      handleSetActiveTab(ContextOption.cro);
    }
    if (
      selectedContext?.type === ContextType.PROFILE_CENTER &&
      activeTab !== ContextOption.profileCenter
    ) {
      handleSetActiveTab(ContextOption.profileCenter);
    }

    if (shouldActiveConfiguration) {
      handleSetActiveTab(ContextOption.configuration);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContext?.type, shouldActiveConfiguration]);

  const triggerIdWithoutHash = triggerId?.substring(1);

  const isBreadcrumbsUnitButton =
    triggerIdWithoutHash === breadcrumbsUnitItemId;

  return (
    <>
      <ac-flex
        id="context-select-wrapper"
        class={isMenuV2EnabledCached ? '' : 'ac-spacing-right-s'}
        direction={FlexDirection.row}
        alignItems={AlignItems.center}
      >
        <ac-click-outside
          onClickOutsideCallback={
            isBreadcrumbsUnitButton ? undefined : closeList
          }
        >
          {!isContextSwitchHidden && (
            <ContextDropdown
              selectedContext={selectedContext}
              businessDate={date}
              dateFormat={dateFormat}
              propertyTime={time}
              color={color}
              isListVisible={isListVisible}
              onClick={openList}
              currentButtonOption={currentButtonOption}
              toShowOnlyIconInDropDown={
                currentButtonOption &&
                currentButtonOption.id === ContextOption.configuration
              }
              tooltipContent={t('MENU.CONTEXT_SWITCH.TOOLTIP')}
            />
          )}
          <GenericList
            list={allContextLists.get(activeTab)}
            selectedContext={selectedContext}
            isVisible={isListVisible}
            onSelect={handleSelect}
            onClose={closeList}
            buttonSelectOptions={tabsOptions}
            triggerId={triggerId}
            activeTab={activeTab}
          >
            {showTabs && (
              <ContextHeader
                selectedContext={selectedContext}
                date={date}
                dateLabel={dateLabel}
                time={time}
                timeLabel={timeLabel}
                buttonSelectOptions={tabsOptions}
                setActiveTab={handleSetActiveTab}
                activeTab={activeTab}
                isContextSwitchHidden={isContextSwitchHidden}
              />
            )}
          </GenericList>
        </ac-click-outside>
      </ac-flex>
    </>
  );
};
