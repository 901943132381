import { Context } from 'frontend-container/components/Menu/components/Context';
import { GlobalSearchModalContentBody } from 'frontend-container/components/Menu/components/GlobalSearchModal/components/GlobalSearchModalContentBody';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { TransparentContainerTheme } from '@ac/web-components';

import './GlobalSearchModal.scss';

interface Props {
  selectedProperty: Context | undefined;
  selectedCro: Context | undefined;
  selectedProfileCenter: Context | undefined;
  mainApplicationMenu: MenuElement[];
  onCloseCallback: () => void;
}

export const GlobalSearchModal = ({
  selectedCro,
  selectedProfileCenter,
  selectedProperty,
  mainApplicationMenu,
  onCloseCallback,
}: Props): JSX.Element | null => {
  return (
    <ac-transparent-container
      theme={TransparentContainerTheme.dark}
      onClickHandler={onCloseCallback}
      class="global-search-modal"
    >
      <GlobalSearchModalContentBody
        mainApplicationMenu={mainApplicationMenu}
        selectedProperty={selectedProperty}
        selectedCro={selectedCro}
        selectedProfileCenter={selectedProfileCenter}
        onCloseCallback={onCloseCallback}
      />
    </ac-transparent-container>
  );
};
