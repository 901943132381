import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { handleMenuItemClick } from 'frontend-container/components/Menu/components/MegaMenu/handleMenuItemClick';
import { MEGA_MENU_ELEMENTS_WITH_SINGLE_OPTION } from 'frontend-container/components/Menu/components/MegaMenu/megaMenuConstants';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { parseMacShortcutsToViewMode } from 'frontend-container/components/Menu/utils/parseMacShortcusToViewMode';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import {
  ColorsPaletteHEX,
  IconName,
  MenuItemType,
  MenuSlot,
} from '@ac/web-components';

interface Props {
  isMac: boolean;
  menuSlot?: MenuSlot;
  menuElements: MenuElement[];
  selectedContextCode?: string;
  withoutHeader?: boolean;
  onCloseCallback?: () => void;
}

export const MegaMenuItem = ({
  isMac,
  menuSlot,
  menuElements,
  selectedContextCode,
  withoutHeader,
  onCloseCallback,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const propertyId = SessionService.getPropertyId() ?? '';
  const tenantId = LoginService.authData()?.tenantId ?? '';

  return (
    <ac-menu-group slot={menuSlot}>
      {menuElements.map((menuElement) => {
        const isElementWithSingleOption =
          MEGA_MENU_ELEMENTS_WITH_SINGLE_OPTION.includes(menuElement.id);

        const firstSubitem = menuElement.items?.[0];

        const headerKey = isElementWithSingleOption
          ? firstSubitem.translation
          : menuElement.translation;

        const headerHotkey = !isElementWithSingleOption
          ? undefined
          : isMac
          ? parseMacShortcutsToViewMode(firstSubitem?.keyboardShortcutMac)
          : firstSubitem?.keyboardShortcutWindows;

        return (
          <Fragment key={headerKey}>
            {!withoutHeader && (
              <ac-menu-item
                icon={menuElement.icon}
                label={t(headerKey)}
                type={MenuItemType.header}
                link={
                  isElementWithSingleOption ? firstSubitem?.link : undefined
                }
                hotkey={headerHotkey}
                badge={
                  selectedContextCode
                    ? {
                        content: selectedContextCode,
                        color: ColorsPaletteHEX.gray200,
                      }
                    : undefined
                }
                onClickCallback={
                  isElementWithSingleOption
                    ? (): void => {
                        handleMenuItemClick(
                          firstSubitem?.link,
                          menuElement.items
                        );
                        onCloseCallback?.();
                      }
                    : undefined
                }
              />
            )}
            {isElementWithSingleOption
              ? null
              : menuElement.items?.map((menuElementItem) => {
                  const translationKey = menuElementItem.translation;

                  const fullLink = menuElementItem.link
                    .replace(/:id/g, propertyId)
                    .replace(/:customerId/g, tenantId);

                  return (
                    <ac-menu-item
                      link={fullLink}
                      key={menuElementItem.link}
                      label={t(translationKey)}
                      onClickCallback={(): void => {
                        handleMenuItemClick(
                          menuElementItem.link,
                          menuElement.items
                        );
                        onCloseCallback?.();
                      }}
                      hotkey={
                        isMac
                          ? parseMacShortcutsToViewMode(
                              menuElementItem.keyboardShortcutMac
                            )
                          : menuElementItem.keyboardShortcutWindows
                      }
                      rightIcon={
                        menuElementItem.isExternal
                          ? { icon: IconName.externalLink }
                          : undefined
                      }
                    />
                  );
                })}
          </Fragment>
        );
      })}
    </ac-menu-group>
  );
};
