import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import { propertyPermissionKeys, Setting } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const groupsPermissions = propertyPermissionKeys.groups;
const inventoryPermissions = propertyPermissionKeys.inventory;

export const groupsDashboardLink = `${acConfig.newFrontendUrls.reservationsGroups}/dashboard`;
export const GROUPS_MENU_ID = 'menu-groups';

export const getGroupsMenu = (): MenuElement => {
  const propertyContextData = getPropertyContextData();

  const hasBlocksSetting = (
    propertyContextData?.settings.effectiveSettingsDetails.find(
      ({ code }) => code === Setting.Blocks
    )?.value as {
      value: boolean;
    }
  )?.value;

  const hasViewBlockDashboardPermission =
    propertyContextData?.permissions.permissionIds.some(
      (permissionId) =>
        permissionId === inventoryPermissions.blocks.viewBlockDashboard
    );

  const groupsMenuTranslation =
    hasViewBlockDashboardPermission && hasBlocksSetting
      ? 'MENU.GROUPS.TITLE.GROUPS_AND_BLOCKS'
      : 'MENU.GROUPS.TITLE.GROUPS';

  return {
    translation: groupsMenuTranslation,
    icon: IconName.group,
    id: GROUPS_MENU_ID,
    items: [
      {
        link: groupsDashboardLink,
        code: 'GroupsDashboard',
        aliases: [
          `${acConfig.newFrontendUrls.reservations}/reservations-groups`,
          `${acConfig.newFrontendUrls.itinerary}/integrated-booking/group`,
        ],
        translation: 'MENU.GROUPS.ITEMS.DASHBOARD',
        permissionsConfiguration: {
          permissions: [
            { key: groupsPermissions.viewGroup, source: AccessSource.Property },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}G`,
        keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}G`,
        keyCode: 71,
      },
      {
        link: `${acConfig.newFrontendUrls.reservations}/reservations-groups/new/details`,
        aliases: [
          `${acConfig.newFrontendUrls.reservations}/reservations-groups/new/room-grid`,
        ],
        translation: 'MENU.GROUPS.ITEMS.NEW',
        permissionsConfiguration: {
          permissions: [
            {
              key: groupsPermissions.createGroup,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
      {
        link: `${acConfig.newFrontendUrls.reservations}/reservations-groups/quick/details`,
        aliases: [
          `${acConfig.newFrontendUrls.reservations}/reservations-groups/quick/room-grid`,
        ],
        translation: 'MENU.GROUPS.ITEMS.QUICK',
        permissionsConfiguration: {
          permissions: [
            {
              key: groupsPermissions.createQuickBookingGroup,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
      {
        link: `${acConfig.newFrontendUrls.reservationsGroups}/availability-dashboard`,
        translation: 'MENU.GROUPS.ITEMS.AVAILABILITY_DASHBOARD',
        permissionsConfiguration: {
          permissions: [
            { key: groupsPermissions.viewGroup, source: AccessSource.Property },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
      {
        link: `${acConfig.newFrontendUrls.inventory}/blocks`,
        aliases: [
          `${acConfig.newFrontendUrls.inventory}/block`,
          `${acConfig.newFrontendUrls.inventory}/block/:id/details`,
          `${acConfig.newFrontendUrls.inventory}/block/:id/room-allocation`,
        ],
        translation: 'MENU.GROUPS.ITEMS.BLOCK_MANAGER',
        permissionsConfiguration: {
          permissions: [
            {
              key: inventoryPermissions.blocks.viewBlockDashboard,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        settings: [
          {
            key: Setting.Blocks,
            source: AccessSource.Property,
          },
        ],
      },
    ],
  };
};
