import {
  AccessSource,
  AllAccessConfiguration,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  customerPermissionKeys,
  propertyPermissionKeys,
  systemPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const auditChangelogUrl = `${acConfig.newFrontendUrls.changelog}/changelog`;

export const deviceManagerUrl = `${acConfig.newFrontendUrls.changelog}/device-manager`;

const isDeviceManagerAllowed = (
  configuration: AllAccessConfiguration
): boolean => {
  return (
    !!configuration[configuration.currentSource].settings.GUSETSELFSERVICE ||
    !!configuration[configuration.currentSource].settings.EREGCARD
  );
};

export const changelogMenu: MenuElement = {
  translation: 'MENU.LOG.TITLE_WHEN_INTEGRATION_LOGGING_DASHBOARD_ENABLED',
  icon: IconName.accessHistory,
  id: 'menu-changelog',
  items: [
    {
      link: auditChangelogUrl,
      translation: 'MENU.LOG.ITEMS.CHANGELOG',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.auditLog.propertyLevelAudit,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}H`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}H`,
      keyCode: 72,
    },
    {
      link: deviceManagerUrl,
      translation: 'MENU.DEVICE_MANAGER.ITEMS.DEVICE_MANAGER',
      allowedWhen: (params) => isDeviceManagerAllowed(params.configuration),
    },
    {
      link: `${acConfig.newFrontendUrls.changelog}/technical-events-log`,
      translation: 'MENU.LOG.ITEMS.TECHNICAL_EVENTS_LOG',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.auditLog.propertyLevelAudit,
            source: AccessSource.Property,
          },
          {
            key: customerPermissionKeys.auditLog.tenantLevelAuditLog,
            source: AccessSource.Tenant,
          },
          {
            key: systemPermissionKeys.auditLog.systemLevelAuditLog,
            source: AccessSource.System,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}.`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}.`,
      keyCode: 190,
    },
    {
      link: `${acConfig.newFrontendUrls.changelog}/system-integration-logs`,
      translation: 'MENU.LOG.ITEMS.INTEGRATION_LOGS',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.integrationLogs.retrievalPropertyLogs,
            source: AccessSource.Property,
          },
          {
            key: customerPermissionKeys.integrationLogs.retrievalTenantLogs,
            source: AccessSource.Tenant,
          },
          {
            key: systemPermissionKeys.integrationLogs.retrievalSystemLogs,
            source: AccessSource.System,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}-`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}-`,
      keyCode: 189,
    },
  ],
};
