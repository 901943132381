import { Fragment, useState } from 'react';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { ContextSelectButtonContent } from 'frontend-container/components/Menu/components/ContextSelectButton/components/ContextSelectButtonContent';
import { getIsMenuElementItemActive } from 'frontend-container/components/Menu/components/ContextSelectButton/getIsMenuElementItemActive';
import {
  ButtonContextOption,
  ContextOption,
} from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { SafeFloatingWrapper } from 'frontend-container/components/Menu/components/SafeFloatingWrapper/SafeFloatingWrapper';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { MenuElementItem } from 'frontend-container/components/Menu/types';
import { CONTAINER_ROOT_SELECTOR } from 'frontend-container/shared/constants';

import {
  ButtonPattern,
  ButtonTheme,
  DividerColor,
  DividerDirection,
  DividerSpacing,
  FlexGap,
  GenericButton,
  Placement,
  TargetValueObject,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

import { FloatingTree } from '@floating-ui/react';

interface Props {
  showDivider?: boolean;
  allAccessConfiguration?: AllAccessConfiguration;
  buttonSelectOptions?: ButtonContextOption[];
  currentButtonOption?: ButtonContextOption;
  buttonProps?: GenericButton;
  hideIcon?: boolean;
}

const contextSelectButtonId = 'context-select-button';

export const ContextSelectButton = ({
  showDivider = true,
  buttonSelectOptions,
  currentButtonOption,
  buttonProps,
}: Props): JSX.Element | null => {
  const [isElementActive, setIsElementActive] = useState(false);

  const [activeItem, setActiveItem] = useState<string>();

  const resetSelection = (): void => {
    setIsElementActive(false);
    setActiveItem(undefined);
  };

  const handleOnMouseOver = (): void => setIsElementActive(true);
  const handleOnMouseOverItem = (label: string): void => setActiveItem(label);
  const handleOnSubItemClick = async (
    link: string,
    menuElementItems?: MenuElementItem[] | undefined,
    onBeforeRedirectCallback?: () => void
  ): Promise<void> => {
    return handleUnsavedChangesRedirect(
      false,
      link,
      async () =>
        await handleMenuItemRedirect(
          link,
          menuElementItems,
          onBeforeRedirectCallback,
          true
        )
    );
  };

  const isContextButtonVisible =
    buttonSelectOptions && buttonSelectOptions.length < 2;

  if (!currentButtonOption || isContextButtonVisible) {
    return null;
  }

  const isMenuV2Enabled = getIsMenuV2Enabled();

  return (
    <Fragment>
      {isMenuV2Enabled ? (
        <FloatingTree>
          <SafeFloatingWrapper
            isDelayRestMode={true}
            floatingContent={
              <div className="menu-subitems-wrapper layout-direction menu-subitems-container menu-subitems-container-dynamic">
                <div>
                  <div className="breadcrumbs-sub-list-wrapper">
                    {buttonSelectOptions?.map((button) => {
                      return (
                        <Fragment key={button.id}>
                          {button.id === ContextOption.configuration && (
                            <ac-divider
                              color={DividerColor.gray6}
                              spacing={DividerSpacing.xss}
                            />
                          )}
                          <ContextSelectButtonContent
                            button={button}
                            currentButtonOption={currentButtonOption}
                            handleOnSubItemClick={handleOnSubItemClick}
                            handleOnMouseOverItem={handleOnMouseOverItem}
                          />
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            }
          >
            <ac-menu-button
              onMouseEnter={handleOnMouseOver}
              onMouseLeave={resetSelection}
              id={contextSelectButtonId}
              transparent
            >
              <ac-text
                color={TextColor.white}
                size={TextSize.sm}
                weight={TextWeight.normal}
              >
                {currentButtonOption.label}
              </ac-text>
            </ac-menu-button>
          </SafeFloatingWrapper>
        </FloatingTree>
      ) : (
        <Fragment>
          <ac-button
            pattern={ButtonPattern.tertiary}
            theme={ButtonTheme.light}
            {...buttonProps}
            onMouseEnter={handleOnMouseOver}
            onMouseLeave={resetSelection}
            id={contextSelectButtonId}
          >
            <ac-button-content
              text={currentButtonOption.label}
              icon={currentButtonOption.icon}
            />
          </ac-button>
          <ac-position-wrapper
            dynamicClass="menu-subitems-wrapper layout-direction"
            attachTo={CONTAINER_ROOT_SELECTOR}
            isBoundaryContainerApplied={false}
            contentHidden={!isElementActive}
            target={`#${contextSelectButtonId}`}
            targetValue={TargetValueObject.mainMenu}
            placement={Placement.bottomStart}
            onMouseOver={handleOnMouseOver}
            onMouseLeave={resetSelection}
          >
            <div className="menu-subitems-container menu-subitems-container-dynamic">
              {buttonSelectOptions?.map((button) => {
                const isButtonWithSubItems = Boolean(
                  button.subMenuElements?.length
                );

                const buttonId = `context-select-button-item-${button.id}`;

                return (
                  <Fragment key={button.id}>
                    <SubItem
                      item={{ link: button.url, translation: button.label }}
                      active={currentButtonOption.id === button.id}
                      onClick={button.onClick}
                      id={buttonId}
                      onMouseOver={(): void =>
                        handleOnMouseOverItem(button.label)
                      }
                      showActionArrow={isButtonWithSubItems}
                      spacing={FlexGap.sm}
                      badgeContent={
                        isMenuV2Enabled && currentButtonOption.id !== button.id
                          ? button.unitName
                          : undefined
                      }
                    />

                    {isButtonWithSubItems && (
                      <ac-position-wrapper
                        dynamicClass="menu-subitems-wrapper layout-direction"
                        attachTo={CONTAINER_ROOT_SELECTOR}
                        contentHidden={activeItem !== button.label}
                        target={`#${buttonId}`}
                        targetValue={TargetValueObject.mainMenu}
                        placement={Placement.rightStart}
                        onMouseOver={handleOnMouseOver}
                        onMouseLeave={resetSelection}
                        offset={{
                          distanceFromTarget: -4,
                          shiftFromTheMiddle: 0,
                        }}
                      >
                        <div className="menu-subitems-container menu-subitems-container-dynamic">
                          {button.subMenuElements?.map((item) => (
                            <SubItem
                              item={item}
                              key={item.link}
                              active={getIsMenuElementItemActive(item)}
                              onClick={handleOnSubItemClick}
                            />
                          ))}
                        </div>
                      </ac-position-wrapper>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </ac-position-wrapper>
          {showDivider && (
            <ac-divider
              direction={DividerDirection.vertical}
              class="bg-gray2"
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
