import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import { MenuElementItem } from 'frontend-container/components/Menu/types';

export const handleMenuItemClick = (
  menuLink: string,
  items: MenuElementItem[]
): void => {
  const redirectToLink = (link: string): Promise<void> =>
    handleMenuItemRedirect(link, items);

  const handleClickMenuSubItem = async (
    link: string,
    discardUnsavedChanges: boolean = false
  ): Promise<void> => {
    return handleUnsavedChangesRedirect(
      discardUnsavedChanges,
      link,
      redirectToLink
    );
  };

  handleClickMenuSubItem(menuLink);
};
