import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  propertyPermissionKeys,
  TasksCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const taskManagementMenu: MenuElement = {
  translation: 'MENU.TASK_MANAGEMENT.TITLE',
  icon: IconName.activities,
  id: 'menu-task-management',
  settings: [
    {
      key: TasksCustomerSettingsKeys.UseTaskManagement,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.taskManagement}/dashboard`,
      aliases: [acConfig.newFrontendUrls.taskManagement],
      code: 'TaskManagementDashboard',
      translation: 'MENU.TASK_MANAGEMENT.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.tasks.viewAndUpdateTasks,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}T`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}T`,
      keyCode: 84,
    },
  ],
};
