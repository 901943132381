import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { TFunction } from 'i18next';

export const mapMenuElementsToGlobalSearchElement = (
  menuElements: MenuElement[],
  t: TFunction,
  badgeLabel?: string | undefined
): GlobalSearchMenuElementItem[] => {
  const output: GlobalSearchMenuElementItem[] = [];
  menuElements.forEach((menuElement) => {
    menuElement.items.forEach((menuItem) => {
      // remove forbidden symbols for id
      const newId = menuItem.link.replace(/[:/]+/g, '-');

      output.push({
        ...menuItem,
        translation: t(menuItem.translation),
        icon: menuElement.icon,
        id: newId,
        adjustedId: newId,
        parentName: t(menuElement.translation),
        isMenuItem: true,
        badgeLabel,
      });
    });
  });

  return output;
};
