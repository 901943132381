import { useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import {
  getCroContexts,
  isCroEnabled,
} from 'frontend-container/components/Menu/components/CroContext/service';
import { handleMenuItemClick } from 'frontend-container/components/Menu/components/MegaMenu/handleMenuItemClick';
import { MegaMenuItem } from 'frontend-container/components/Menu/components/MegaMenu/MegaMenuItem';
import {
  getProfileCentersContexts,
  isProfileCentersEnabled,
} from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/centralAvailabilityInquiry';
import { profileCenterMenu } from 'frontend-container/components/Menu/configuration/profileCenters';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/reservationsLookup';
import { useContextsContext } from 'frontend-container/components/Menu/store/context';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { getSortedMenuItems } from 'frontend-container/components/Menu/utils/getSortedMenuItems';

import { acConfig } from '@ac/library-utils/dist/declarations';
import {
  DividerColor,
  DividerSpacing,
  IconName,
  MenuSlot,
} from '@ac/web-components';

import './MegaMenu.scss';

interface Props {
  isVisible: boolean;
  selectedCro: Context | undefined;
  selectedProfileCenter: Context | undefined;
  onCloseCallback?: () => void;
  allAccessConfiguration: AllAccessConfiguration | undefined;
  mainMenuItems: MenuElement[];
}

export const MegaMenu = ({
  isVisible,
  selectedCro,
  selectedProfileCenter,
  onCloseCallback,
  allAccessConfiguration,
  mainMenuItems,
}: Props): JSX.Element | null => {
  const { allContextLists } = useContextsContext();

  const croContexts = useMemo(() => getCroContexts(), []);
  const profileCentersContexts = useMemo(() => getProfileCentersContexts(), []);

  const configuration = allContextLists.get('configuration') as
    | ButtonContextOption
    | undefined;

  const isMac = navigator.appVersion.indexOf('Mac') !== -1;

  const sortedItems = useMemo((): MenuElement[][] => {
    return getSortedMenuItems(mainMenuItems);
  }, [mainMenuItems]);

  const centralAvailabilityInquiryMenuItem = useMemo(() => {
    return {
      ...centralAvailabilityInquiryMenu.items[0],
      translation: `${centralAvailabilityInquiryMenu.items[0].translation}`,
    };
  }, []);

  const reservationsLookupMenuItem = useMemo(() => {
    return {
      ...reservationsLookupMenu.items[0],
      translation: `${reservationsLookupMenu.items[0].translation}`,
    };
  }, []);

  const shortcutsWindows = sortedItems
    .flat()
    .flatMap((element: MenuElement) =>
      element.items.filter((item) => item.keyboardShortcutWindows)
    );
  const shortcutsMac = sortedItems
    .flat()
    .flatMap((element: MenuElement) =>
      element.items.filter((item) => item.keyboardShortcutMac)
    );
  const keyboardShortcuts = isMac
    ? shortcutsMac.map((item: MenuElementItem) => item.keyboardShortcutMac)
    : shortcutsWindows.map(
        (item: MenuElementItem) => item.keyboardShortcutWindows
      );

  useHotkeys(
    keyboardShortcuts.join(),
    (handler: {
      altKey: boolean;
      ctrlKey: boolean;
      metaKey: boolean;
      keyCode: number;
    }) => {
      const shortcuts = isMac ? shortcutsMac : shortcutsWindows;

      const foundMenuItem = shortcuts.find((item: MenuElementItem) => {
        const { altKey, ctrlKey, metaKey, keyCode } = handler;
        const isTheSameKeyCode = keyCode === item.keyCode;

        return altKey && (isMac ? metaKey : ctrlKey) && isTheSameKeyCode;
      });
      if (foundMenuItem) {
        handleMenuItemClick(foundMenuItem.link, [foundMenuItem]);
        onCloseCallback?.();
      }
    }
  );

  const configurationMainItems = configuration?.subMenuElements?.filter(
    (configurationSubElement) =>
      configurationSubElement.link.startsWith(
        `${acConfig.newFrontendUrls.configurationv2}/customers`
      )
  );
  const configurationSecondaryItems = configuration?.subMenuElements?.filter(
    (configurationSubElement) =>
      !configurationSubElement.link.startsWith(
        `${acConfig.newFrontendUrls.configurationv2}/customers`
      )
  );

  const allowedProfileCenter = useMemo(() => {
    if (allAccessConfiguration) {
      return getAllowedMenuItems([profileCenterMenu], allAccessConfiguration);
    }

    return [];
  }, [allAccessConfiguration]);

  return !isVisible ? null : (
    <ac-menu
      isVisible={isVisible}
      onOutsideClickCallback={onCloseCallback}
      class="mega-menu"
    >
      {sortedItems.map((menuItem) => (
        <MegaMenuItem
          menuSlot={MenuSlot.left}
          menuElements={menuItem}
          key={menuItem[0].id}
          isMac={isMac}
          onCloseCallback={onCloseCallback}
        />
      ))}
      {!!(
        isProfileCentersEnabled() &&
        !!allowedProfileCenter?.length &&
        !!profileCentersContexts?.length
      ) && (
        <MegaMenuItem
          selectedContextCode={selectedProfileCenter?.code}
          menuSlot={MenuSlot.right}
          isMac={isMac}
          onCloseCallback={onCloseCallback}
          menuElements={[
            {
              ...allowedProfileCenter[0],
              icon: IconName.perPax,
              items: allowedProfileCenter[0].items,
            },
          ]}
        />
      )}
      {!!isCroEnabled() && !!croContexts?.length && (
        <MegaMenuItem
          selectedContextCode={selectedCro?.code}
          menuSlot={MenuSlot.right}
          isMac={isMac}
          onCloseCallback={onCloseCallback}
          menuElements={[
            {
              translation: 'MENU.CRO.TITLE',
              icon: IconName.cro,
              id: 'CRO-mega-menu-id',
              items: [
                centralAvailabilityInquiryMenuItem,
                reservationsLookupMenuItem,
              ],
            },
          ]}
        />
      )}
      {!!configuration && !!configurationMainItems?.length && (
        <MegaMenuItem
          menuSlot={MenuSlot.right}
          isMac={isMac}
          onCloseCallback={onCloseCallback}
          menuElements={[
            {
              translation: configuration.label,
              id: configuration.id,
              icon: IconName.settings,
              items: configurationMainItems ?? [],
            },
          ]}
        />
      )}
      {!!configurationMainItems?.length &&
        !!configurationSecondaryItems?.length && (
          <ac-box
            slot={MenuSlot.right}
            class="ac-padding-inline-end-md ac-padding-inline-start-xlg"
          >
            <ac-divider
              color={DividerColor.gray200}
              spacing={DividerSpacing.none}
            />
          </ac-box>
        )}

      {!!configuration && !!configurationSecondaryItems?.length && (
        <MegaMenuItem
          menuSlot={MenuSlot.right}
          isMac={isMac}
          onCloseCallback={onCloseCallback}
          withoutHeader={true}
          menuElements={[
            {
              translation: configuration.label,
              id: configuration.id,
              icon: IconName.settings,
              items: configurationSecondaryItems ?? [],
            },
          ]}
        />
      )}
    </ac-menu>
  );
};
