import { accountsReceivableMenu } from 'frontend-container/components/Menu/configuration/accountsReceivable';
import { ACTIVITY_RESERVATIONS_MENU_ID } from 'frontend-container/components/Menu/configuration/activityReservations';
import { cashieringMenu } from 'frontend-container/components/Menu/configuration/cashiering';
import { changelogMenu } from 'frontend-container/components/Menu/configuration/changelog';
import { commissionsMenu } from 'frontend-container/components/Menu/configuration/commissions';
import { endOfDayMenu } from 'frontend-container/components/Menu/configuration/endOfDay';
import { frontdeskMenu } from 'frontend-container/components/Menu/configuration/frontdesk';
import { GOLF_MENU_ID } from 'frontend-container/components/Menu/configuration/golf';
import { GROUPS_MENU_ID } from 'frontend-container/components/Menu/configuration/groups';
import { housekeepingMenu } from 'frontend-container/components/Menu/configuration/housekeeping';
import { inquiryMenu } from 'frontend-container/components/Menu/configuration/inquiry';
import { integrationsMenu } from 'frontend-container/components/Menu/configuration/integrations';
import { meetingsAndEventsMenu } from 'frontend-container/components/Menu/configuration/meetingsAndEvents';
import { profileCenterMenu } from 'frontend-container/components/Menu/configuration/profileCenters';
import { profilesMenu } from 'frontend-container/components/Menu/configuration/profiles';
import { propertyUsers } from 'frontend-container/components/Menu/configuration/propertyUsers';
import { ratesMenu } from 'frontend-container/components/Menu/configuration/rates';
import { reportsMenu } from 'frontend-container/components/Menu/configuration/reports';
import { reservationsMenu } from 'frontend-container/components/Menu/configuration/reservations';
import { resourceManagementMenu } from 'frontend-container/components/Menu/configuration/resourceManagement';
import { salesActivitiesMenu } from 'frontend-container/components/Menu/configuration/salesActivities';
import { taskManagementMenu } from 'frontend-container/components/Menu/configuration/taskManagement';
import { MENU_LINKS_ID } from 'frontend-container/components/Menu/constants';

export const MEGA_MENU_LEFT_ORDER: string[][] = [
  [inquiryMenu.id, reservationsMenu.id],
  [frontdeskMenu.id],
  [cashieringMenu.id],
  [profilesMenu.id],
  [meetingsAndEventsMenu.id],
  [GROUPS_MENU_ID],
  [ACTIVITY_RESERVATIONS_MENU_ID],
  [GOLF_MENU_ID],
  [housekeepingMenu.id],
  [ratesMenu.id],
  [reportsMenu.id],
  [
    taskManagementMenu.id,
    salesActivitiesMenu.id,
    propertyUsers.id,
    resourceManagementMenu.id,
  ],
  [commissionsMenu.id, accountsReceivableMenu.id],
  [integrationsMenu.id],
  [changelogMenu.id],
  [endOfDayMenu.id],
  [MENU_LINKS_ID],
];

export const MEGA_MENU_ELEMENTS_WITH_SINGLE_OPTION = [
  inquiryMenu.id,
  commissionsMenu.id,
  taskManagementMenu.id,
  salesActivitiesMenu.id,
  propertyUsers.id,
  profileCenterMenu.id,
];
