import {
  FC,
  LegacyRef,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { MenuElementItem } from 'frontend-container/components/Menu/types';
import { parseMacShortcutsToViewMode } from 'frontend-container/components/Menu/utils/parseMacShortcusToViewMode';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { ColorsPaletteHEX, FlexGap, IconName, Size } from '@ac/web-components';

import './MenuLinks.scss';

interface Props {
  wrapperClasses: string;
  onClick: (link: string) => void;
  onMouseOver?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  item: MenuElementItem;
  active: boolean;
  id?: string;
  showActionArrow?: boolean;
  spacing?: FlexGap;
  itemRef?: LegacyRef<HTMLDivElement>;
  itemProps?: Record<string, unknown>;
  badgeContent?: string;
}

export const InternalMenuLink: FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const isMac = navigator.appVersion.indexOf('Mac') !== -1;
  const propertyId = SessionService.getPropertyId() ?? '';
  const fullLink = props.item.link
    .replace(/:id/g, propertyId)
    .replace(/:customerId/g, LoginService.authData()?.tenantId ?? '');

  const shortcutsClassNames = [
    'subitem-shortcut',
    !props.active ? 'not-selected' : undefined,
  ]
    .filter(Boolean)
    .join(' ');

  const handleOnClick = (
    event: ReactMouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.preventDefault();
    if (!props.showActionArrow) {
      props.onClick(props.item.link);
    }
  };

  const label = useMemo(
    () =>
      i18n.exists(props.item.translation)
        ? t(props.item.translation)
        : props.item.translation,
    [i18n, props.item.translation, t]
  );

  const keyboardShortcut = isMac
    ? parseMacShortcutsToViewMode(props.item.keyboardShortcutMac)
    : props.item.keyboardShortcutWindows;

  const linkProps = props.itemProps ?? {};
  const isMenuV2Enabled = getIsMenuV2Enabled();

  return (
    <div
      className={props.wrapperClasses}
      onClick={handleOnClick}
      id={props.id}
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
      ref={props.itemRef}
      {...linkProps}
    >
      <a
        className={`internal-link ac-gap-${props.spacing ?? 'xlg'}`}
        target="_blank"
        rel="noopener noreferrer"
        href={fullLink}
      >
        <div>
          {label}
          {props.badgeContent && (
            <ac-badge
              content={props.badgeContent}
              color={ColorsPaletteHEX.gray100}
              class="ac-margin-inline-start-sm"
            />
          )}
        </div>
        {keyboardShortcut && (
          <span className={shortcutsClassNames}>{keyboardShortcut}</span>
        )}
        {props.showActionArrow && (
          <ac-icon
            icon={IconName.actionRight}
            size={isMenuV2Enabled ? Size.xs : Size.sm}
          />
        )}
      </a>
    </div>
  );
};
