import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import { FeatureToggleName, propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const integrationsPermissions = propertyPermissionKeys.integrations;

export const integrationsMenu: MenuElement = {
  translation: 'MENU.INTEGRATIONS.TITLE',
  icon: IconName.structure,
  id: 'menu-integrations',
  items: [
    {
      link: `${acConfig.newFrontendUrls.integrations}/dashboard`,
      aliases: [acConfig.newFrontendUrls.integrations],
      translation: 'MENU.INTEGRATIONS.ITEMS.INTERFACES',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}\\`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}\\`,
      keyCode: 220,
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/webhook-management`,
      translation: 'MENU.INTEGRATIONS.ITEMS.WEBHOOK',
      permissionsConfiguration: {
        permissions: [
          {
            key: integrationsPermissions.webhookManagementViewSubscription,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/:id/data-migration/dashboard`,
      aliases: [acConfig.newFrontendUrls.integrations],
      translation: 'MENU.INTEGRATIONS.ITEMS.DATA_MIGRATION',
      permissionsConfiguration: {
        permissions: [
          {
            key: integrationsPermissions.dataMigrationViewDashboard,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/migration-authorization`,
      translation: 'MENU.INTEGRATIONS.ITEMS.MIGRATION_AUTHORIZATION_REQUESTS',
      featureToggles: [
        {
          key: FeatureToggleName.MigrationRestrictedExecution,
          source: AccessSource.Tenant,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/grs-management/dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.GRS_MANAGEMENT',
      featureToggles: [
        {
          key: FeatureToggleName.IntegrationIHG,
          source: AccessSource.Tenant,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/central-integration-manager/dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.CENTRAL_INTEGRATION_MANAGER',
      featureToggles: [
        {
          key: FeatureToggleName.CentralIntegrationManagerPhase1,
          source: AccessSource.Tenant,
        },
      ],
    },
  ],
};

export const integrationsMenuForSystemUser: MenuElement = {
  translation: 'MENU.INTEGRATIONS.TITLE',
  icon: IconName.structure,
  id: 'menu-integrations',
  items: [
    {
      link: `${acConfig.newFrontendUrls.integrations}/central-integration-manager/dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.CENTRAL_INTEGRATION_MANAGER',
      featureToggles: [
        {
          key: FeatureToggleName.CentralIntegrationManagerPhase1,
          source: AccessSource.System,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/migration-authorization/system-user-dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.DATA_MIGRATION_AUTHORIZATION',
      featureToggles: [
        {
          key: FeatureToggleName.MigrationRestrictedExecution,
          source: AccessSource.System,
        },
      ],
    },
  ],
};
