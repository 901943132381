import {
  croLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage';
import { profileCenterLandingPagePathname } from 'frontend-container/components/LandingPage/location';
import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import {
  getCroContextById,
  getCroContexts,
  hasAnyCroContext,
  isCroEnabled,
} from 'frontend-container/components/Menu/components/CroContext/service';
import {
  getProfileCenterContextById,
  getProfileCentersContexts,
  hasAnyProfileCentersContext,
  isProfileCentersEnabled,
} from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import {
  getPropertyContextById,
  getPropertyContexts,
  getPropertyUnitById,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import {
  FullCurrentUser,
  userService,
} from 'frontend-container/components/Menu/components/User/service';
import {
  DEFAULT_LANGUAGE,
  SystemDefaultLandingPage,
} from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import { getLanguageSettings } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/getLanguageSettings';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/configuration';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { updateUserPreferences } from 'frontend-container/shared/businessContext/mutators';
import { getApiConfigForConfigurationRequests } from 'frontend-container/utils/api/getApiConfigForConfigurationRequests';
import {
  isAnyConfiguration,
  isLoginUrl,
  isMainUrl,
} from 'frontend-container/utils/location';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';
import { setUILanguage } from 'frontend-container/utils/UILanguage';

import {
  getIfMatchConfig,
  UserPreferences,
  UserPreferencesDetails,
} from '@ac/library-api';
import { UserPreferencesApi } from '@ac/library-api/dist/api/v0/configuration/users';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

const saveUserPreferences = async (
  preferences: UserPreferencesDetails
): Promise<boolean> => {
  try {
    const loadedData = userService.getCurrentUserPreferences();

    if (!loadedData) {
      throw new Error('No user preferences found');
    }

    await UserPreferencesApi.putMyPreferences({
      data: {
        preferences,
      },
      customConfig: getApiConfigForConfigurationRequests({
        headers: getIfMatchConfig(loadedData.version).headers,
      }),
    });

    await updateUserPreferences();
  } catch {
    return false;
  }

  return true;
};

const initializeUserPreferences = async (
  newRoute?: string,
  userPreferencesOverwrite?: UserPreferencesDetails
): Promise<void> => {
  if (isError403IpWhitlelistPage()) {
    return;
  }

  const { userPreferences: savedUserPreferences } =
    userService.getFullCurrentUser();

  const userPreferences = {
    ...savedUserPreferences,
    preferences: {
      ...savedUserPreferences?.preferences,
      ...userPreferencesOverwrite,
    },
  } as UserPreferences;

  initializeDefaultContexts(userPreferences);
  await initializeDefaultLanguage(userPreferences);
  initializeDefaultLandingPage(userPreferences, newRoute);
};

const initializeDefaultContexts = (
  userPreferences: UserPreferences | undefined
) => {
  initializeDefaultProperty(userPreferences);
  initializeDefaultCRO(userPreferences);
  initializeDefaultProfileCenter(userPreferences);
};

const initializeDefaultProperty = (
  userPreferences: UserPreferences | undefined
): void => {
  const propertyId = SessionService.getPropertyId();
  if (propertyId) {
    if (getPropertyContextById(propertyId)) {
      return;
    }
    SessionService.setPropertyId(undefined);
  }

  setDefaultProperty(userPreferences?.preferences);
};

const initializeDefaultCRO = (userPreferences?: UserPreferences): void => {
  const croId = SessionService.getCentralReservationOfficeId();

  if (croId) {
    if (getCroContextById(croId)) {
      return;
    }
    SessionService.setCentralReservationOfficeId(undefined);
  }

  setDefaultCRO(userPreferences?.preferences);
};

const initializeDefaultProfileCenter = (
  userPreferences?: UserPreferences
): void => {
  const profileCenterId = SessionService.getProfileCenterId();

  if (profileCenterId) {
    if (getProfileCenterContextById(profileCenterId)) {
      return;
    }
    SessionService.setProfileCenterId(undefined);
  }

  setDefaultProfileCenter(userPreferences?.preferences);
};

const setDefaultCRO = (userPreferences?: UserPreferencesDetails): void => {
  const croContexts = getCroContexts();

  const context = selectInitialContext(
    croContexts,
    userPreferences?.defaultCentralReservationOfficeId
  );

  if (!context) {
    return;
  }

  setSessionContext(context);
};

const setDefaultProfileCenter = (
  userPreferences?: UserPreferencesDetails
): void => {
  const profileCentersContexts = getProfileCentersContexts();

  const context = selectInitialContext(
    profileCentersContexts,
    userPreferences?.defaultProfileCenterId
  );

  if (!context) {
    return;
  }

  setSessionContext(context);
};

const setDefaultProperty = (userPreferences?: UserPreferencesDetails): void => {
  const contexts = getPropertyContexts();

  const context = selectInitialContext(
    contexts,
    userPreferences?.defaultPropertyId
  );

  if (!context) {
    return;
  }

  setSessionContext(context);
};

const initializeDefaultLanguage = async (
  userPreferences: UserPreferences | undefined
): Promise<void> => {
  const user: FullCurrentUser | undefined = userPreferences
    ? undefined
    : userService.getFullCurrentUser();

  const languageSettings = getLanguageSettings();

  const defaultLanguage = (
    user?.userPreferences?.preferences?.defaultLanguage ??
    userPreferences?.preferences?.defaultLanguage ??
    languageSettings?.value.languageCode ??
    DEFAULT_LANGUAGE
  ).toLowerCase();
  await setUILanguage(defaultLanguage);
};

const initializeDefaultLandingPage = (
  userPreferences: UserPreferences | undefined,
  newRoute: string | undefined
): void => {
  const pathname = window.location.pathname;
  const isCurrentUrlConfiguration = isAnyConfiguration(pathname);
  const shouldRedirectToConfiguration =
    !isCurrentUrlConfiguration &&
    getIsEmptyTenant() &&
    !LoginService.isSuperUser();

  if (shouldRedirectToConfiguration) {
    const customerId = LoginService.authData()?.tenantId;
    const organizationStructureLink =
      configurationMenu.items[1].link.replace(
        /:customerId/g,
        customerId ?? ''
      ) ?? SystemDefaultLandingPage.pathname;

    return history.replaceState({}, '', organizationStructureLink);
  }

  const defaultLandingPageRoute = shouldNavigateToCroLandingPage(
    userPreferences
  )
    ? croLandingPagePathname
    : shouldNavigateToProfileCenterLandingPage(userPreferences)
    ? profileCenterLandingPagePathname
    : propertyLandingPagePathname;

  if (newRoute) {
    const newPathname = !(isMainUrl(newRoute) || isErrorPage(newRoute))
      ? newRoute
      : defaultLandingPageRoute;
    if (!newPathname.startsWith(pathname)) {
      return history.replaceState({}, '', newPathname);
    }
  }

  if (isLoginUrl(pathname) || isMainUrl(pathname)) {
    return history.replaceState({}, '', defaultLandingPageRoute);
  }
};

const getIsEmptyTenant = (): boolean => {
  return getPropertyContexts().length + getCroContexts().length === 0;
};

const shouldNavigateToCroLandingPage = (
  userPreferences?: UserPreferences
): boolean => {
  if (LoginService.isSuperUser()) {
    return false;
  }

  const isDefaultUserWorkspaceCRO =
    userPreferences?.preferences?.defaultWorkspace === ContextType.CRO;

  return isCroEnabled() && hasAnyCroContext() && isDefaultUserWorkspaceCRO;
};

const shouldNavigateToProfileCenterLandingPage = (
  userPreferences?: UserPreferences
): boolean => {
  if (LoginService.isSuperUser()) {
    return false;
  }

  const isDefaultUserWorkspaceProfileCenter =
    userPreferences?.preferences?.defaultWorkspace ===
    ContextType.PROFILE_CENTER;

  return (
    isProfileCentersEnabled() &&
    hasAnyProfileCentersContext() &&
    isDefaultUserWorkspaceProfileCenter
  );
};

const validateUserPreferences = async (): Promise<void> => {
  const preferences = userService.getCurrentUserPreferences();
  const preferencesDetails = preferences?.preferences;

  if (!preferencesDetails) {
    return;
  }

  const {
    defaultCentralReservationOfficeId,
    defaultPropertyId,
    defaultProfileCenterId,
  } = preferencesDetails;
  const validatedPreferences = { ...preferencesDetails };

  if (defaultCentralReservationOfficeId) {
    const isDefaultCroEnabled = getCroContextById(
      defaultCentralReservationOfficeId
    );

    if (!isDefaultCroEnabled) {
      validatedPreferences.defaultCentralReservationOfficeId = undefined;
      validatedPreferences.defaultCentralReservationOfficeLandingScreen =
        undefined;
    }
  }

  if (defaultPropertyId) {
    const isDefaultPropertyEnabled = getPropertyUnitById(defaultPropertyId);

    if (!isDefaultPropertyEnabled) {
      validatedPreferences.defaultPropertyId = undefined;
      validatedPreferences.defaultLandingScreen = undefined;
    }
  }

  if (defaultProfileCenterId) {
    const isDefaultProfileCenterEnabled = getProfileCenterContextById(
      defaultProfileCenterId
    );

    if (!isDefaultProfileCenterEnabled) {
      validatedPreferences.defaultProfileCenterId = undefined;
      validatedPreferences.defaultProfileCenterLandingScreen = undefined;
    }
  }

  const isValidated =
    validatedPreferences.defaultCentralReservationOfficeId !==
      defaultCentralReservationOfficeId ||
    validatedPreferences.defaultPropertyId !== defaultPropertyId ||
    validatedPreferences.defaultProfileCenterId !== defaultProfileCenterId;

  if (isValidated) {
    setDefaultCRO(validatedPreferences);
    setDefaultProperty(validatedPreferences);
    setDefaultProfileCenter(validatedPreferences);

    await saveUserPreferences(validatedPreferences);
  }
};

export const userPreferencesService = {
  saveUserPreferences,
  initializeDefaultLanguage,
  initializeDefaultContexts,
  initializeUserPreferences,
  validateUserPreferences,
  setDefaultCRO,
  setDefaultProperty,
  setDefaultProfileCenter,
};
