import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  FeatureToggleName,
  ProfilesCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const profilesPermissions = propertyPermissionKeys.profiles;
const individualProfilePermissions = profilesPermissions.individual;
const profileSharedPermissions = profilesPermissions.shared;

export const profilesDashboardLink = `${acConfig.newFrontendUrls.profiles}/dashboard`;

export const profilesMenu: MenuElement = {
  translation: 'MENU.PROFILES.TITLE',
  icon: IconName.profile,
  id: 'menu-profiles',
  items: [
    {
      link: profilesDashboardLink,
      code: 'ProfileDashboard',
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/dashboard/all`,
        `${acConfig.newFrontendUrls.profiles}/dashboard/individual`,
        `${acConfig.newFrontendUrls.profiles}/dashboard/company`,
        `${acConfig.newFrontendUrls.profiles}/dashboard/travel-agent`,

        // required for proper handling selected item on profile edit pages
        `${acConfig.newFrontendUrls.reservations}/profiles/individual`,
        `${acConfig.newFrontendUrls.reservations}/profiles/company`,
        `${acConfig.newFrontendUrls.reservations}/profiles/travel-agent`,
      ],
      translation: 'MENU.PROFILES.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: profilesPermissions.individual.viewDetails,
            source: AccessSource.Property,
          },
          {
            key: profilesPermissions.company.viewDetails,
            source: AccessSource.Property,
          },
          {
            key: profilesPermissions.travelAgent.viewDetails,
            source: AccessSource.Property,
          },
          {
            key: profileSharedPermissions.dataProtectionOfficer
              .seeProfilesDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}P`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}P`,
      keyCode: 80,
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/duplicates`,
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/duplicates/potential`,
        `${acConfig.newFrontendUrls.profiles}/duplicates/last-merged`,

        `${acConfig.newFrontendUrls.profiles}/preview-merge/individual/:targetId/:sourceId`,
        `${acConfig.newFrontendUrls.profiles}/preview-merge/company/:targetId/:sourceId`,
        `${acConfig.newFrontendUrls.profiles}/preview-merge/travel-agent/:targetId/:sourceId`,
      ],
      translation: 'MENU.PROFILES.ITEMS.POTENTIAL_DUPLICATES',
      permissionsConfiguration: {
        permissions: [
          {
            key: profileSharedPermissions.matchAndMerge
              .viewPotentialDuplicateScreen,
            source: AccessSource.Property,
          },
          {
            key: profileSharedPermissions.matchAndMerge
              .changelogForMergedProfiles,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/individual`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/profiles/individual/new`,
      ],
      translation: 'MENU.PROFILES.ITEMS.CREATE_INDIVIDUAL_PROFILE',
      permissionsConfiguration: {
        permissions: [
          {
            key: individualProfilePermissions.manageDetails,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/company`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/profiles/company/new`,
      ],
      translation: 'MENU.PROFILES.ITEMS.CREATE_COMPANY_PROFILE',
      permissionsConfiguration: {
        permissions: [
          {
            key: individualProfilePermissions.manageDetails,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/travel-agent`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/profiles/travel-agent/new`,
      ],
      translation: 'MENU.PROFILES.ITEMS.CREATE_TRAVELAGENT_PROFILE',
      permissionsConfiguration: {
        permissions: [
          {
            key: profilesPermissions.travelAgent.manageDetails,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/consent-and-activity`,
      translation: 'MENU.PROFILES.ITEMS.CONSENT_AND_ACTIVITY',
      permissionsConfiguration: {
        permissions: [
          {
            key: individualProfilePermissions.manageDetails,
            source: AccessSource.Property,
          },
          {
            key: individualProfilePermissions.viewDetails,
            source: AccessSource.Property,
          },
          {
            key: individualProfilePermissions.viewIncognito,
            source: AccessSource.Property,
          },
          {
            key: individualProfilePermissions.viewConsentAndProfileActivityDashboard,
            source: AccessSource.Property,
          },
          {
            key: individualProfilePermissions.manageConsentsAndPrivacy,
            source: AccessSource.Property,
          },
          {
            key: profileSharedPermissions.dataProtectionOfficer
              .seeProfilesDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/enrollment`,
      translation: 'MENU.PROFILES.ITEMS.ENROLLMENT',
      featureToggles: [
        {
          key: FeatureToggleName.MembershipEnrollment,
          source: AccessSource.Property,
        },
      ],
      settings: [
        {
          key: ProfilesCustomerSettingsKeys.PerformExternalMembershipEnrollment,
          source: AccessSource.Property,
        },
      ],
      permissionsConfiguration: {
        permissions: [
          {
            key: individualProfilePermissions.enrollNewMember,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
